import React from 'react'
import { observer } from 'mobx-react-lite'
import Avatar from '@material-ui/core/Avatar'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

import {
  useGroupStore,
  useUserStore,
} from '../../../state'

import { pickColour } from '../../../utils/color-picker'
import Progress from '../../common/Progress'
import { getTotalRemainingSeats, userIsClubAdmin } from '../../../utils/access-utils'
import SeatsWidget from './SeatsWidget'

const useStyles = makeStyles(theme => ({
  membersContainer: {
    justifyContent: 'flex-start',
    margin: 'auto'
  },
  memberContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: 240,
    marginBottom: 15,
  },
  avatar: {
    flexGrow: 0,
    fontSize: "1.9rem",
    width: 55,
    height: 55,
    marginRight: 12
  },
  username: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#404040",
    flexGrow: 1
  },
}))

const User = ({ username, progress, status, i }) => {
  const userStore = useUserStore();
  const groupStore = useGroupStore();
  const { lessonId } = groupStore.currentClub;
  const classes = useStyles()

  const [isClubAdmin, setIsClubAdmin] = React.useState(false)

  React.useEffect(()=>{
    setIsClubAdmin(userIsClubAdmin(userStore.currentUser, groupStore.currentClub))
  },[userStore.currentUser])

  return (
    <Grid container className={classes.memberContainer}>
      <Grid item>
        <Avatar className={classes.avatar} style={{ backgroundColor: pickColour(i, true), }}>
          {username && username.slice(0, 1).toUpperCase()}
        </Avatar>
      </Grid>
      <Grid item zeroMinWidth style={{ flex: 1 }}>
        <Typography noWrap>
          {username}
        </Typography>
        {
          lessonId && isClubAdmin &&
          <>
            {status ? (
              <Typography>
                {status === 'reviewed' ? 'MARKED' : 'COMPLETED!'}
              </Typography>
            ) : (
                <Progress value={progress} />
              )}
          </>
        }
      </Grid>
    </Grid>
  )
}

const ClubMembers = () => {
  const classes = useStyles();
  const groupStore = useGroupStore();
  const userStore = useUserStore();
  const members = groupStore.clubMembers;
  const [isClubAdmin, setIsClubAdmin] = React.useState(false);
  const [remainingSeats, setRemainingSeats] = React.useState(0);

  React.useEffect(()=>{
    setIsClubAdmin(userIsClubAdmin(userStore.currentUser, groupStore.currentClub));
    setRemainingSeats(getTotalRemainingSeats(userStore.currentUser, groupStore.myClubs));
  },[userStore.currentUser]);

  return (
    <Fade in={true}>
      <div>
        {isClubAdmin || userStore.currentUser.subscriptionType === "admin" && 
          <SeatsWidget />
        }
        <Typography variant={'h1'}>Members</Typography>
        <Grid container spacing={4} className={classes.membersContainer}>
          {members.map((member, i) => {
            const userProps = {
              username: member.username,
              progress: (member.record?.progress)
                ? member.record.progress[0] * 100
                : 0,
              status: member.record && member.record.status
            }

            return (
              <User {...userProps} key={i} i={i} />
            )
          })}
        </Grid>
      </div>
    </Fade>
  )
}

export default observer(ClubMembers)
