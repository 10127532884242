import "./css/main.css"
import {
  Switch,
  Route,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles"
import { createTheme } from "@material-ui/core";
import './css/main.css'
import {
  LessonProvider,
  UserProvider,
  GroupProvider,
  CommentProvider
} from './state'
import Home from './pages'
import Club from './pages/club'
import AuthUtility from "./components/AuthUtility";

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0,0,20,0.3)",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "'Fredoka One', cursive;",
        color: "primary",
        size: "medium"
      },
    },
  },
  palette: {
    primary: {
      light: "#99bee0",
      main: "#0085ff",
      dark: "#0085ff",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    text: {
      primary: "#555e78",
      secondary: "#868686",
      warning: "#fc4b6c"
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif;",
    h1: {
      fontFamily: '"Fredoka One", cursive',
      fontSize: '1.6rem',
      color: '#515873',
      webkitFontSmoothing: "antialiased",
      marginBottom: 8
    },
    h2: {
      fontFamily: '"Fredoka One", cursive',
      fontSize: '1.3rem',
      color: '#515873',
      webkitFontSmoothing: "antialiased",
      marginBottom: 12
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 150,
      lineHeight: 1.5
    },
    subtitle2: {
      color: '#515873',
      fontSize: '1rem',
      lineHeight: 1.5
    }
  },
});

const AppWrapper = () => {
  return (
    <Router>
      <Route path="*">
        <App />
      </Route>
    </Router>
  )
};

function App() {
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <GroupProvider>
          <LessonProvider>
            <CommentProvider>
              <AuthUtility />
              <Switch location={location}>
                <Route
                  exact path="/"
                  component={Home}
                />
                <Route
                  exact path="/group/:id"
                  component={Club}
                />
              </Switch>
            </CommentProvider>
          </LessonProvider>
        </GroupProvider>
      </UserProvider>
    </ThemeProvider>
  )
}

export default AppWrapper