/**Utility function to check if a user has access to preform club admin actions such as create a new club.
 * NOTE: this checks only high level access not ownership access of a given club.
 * @params pass this function the current user from the user store e.g. userStore.currentUser
 * @returns boolean
 */
export const userHasClubsAdminAccess = (
  /**the current user
   * @type User */
  currentUser
  ) => {
  return (
    currentUser.subscriptionType === "admin" ||
    currentUser.subscriptionType === "edu"
  );
};

/**Utility function to check if a user should have access to clubs pages.
 * NOTE: only premium tier users should be able to access clubs.
 * @params pass this function the current user from the user store e.g. userStore.currentUser
 * @returns boolean
 */
export const userHasClubsAccess = (
  /**the current user
   * @type User */
  currentUser
  ) => {
  return (
    userHasClubsAdminAccess(currentUser) ||
    currentUser.features.split(",").includes("code_club")
  );
};

/**Utility function to check if a user is a member of a given club.
 * @params pass this function the current user from the user store e.g.
 *  userStore.currentUser as well as the club Id
 * @returns boolean
 */
export const userIsAffiliatedWithClub = (
  /**the current user
   * @type User */
  currentUser, 
  /**the id of the club being checked
   * @type number */
  groupId
  ) => {
  return !!currentUser.members.find((club) => club.groupId === groupId);
};

/**Utility function to check if a user has admin rights over a given club.
 * @params pass this function the current user and the current club from
 *  the appropriate store e.g. (userStore.currentUser, groupStore.currentClub)
 * @returns boolean
 */
export const userIsClubAdmin = (
  /**the current user
   * @type User */
  currentUser,
  /**the current club
   * @type Group */
  currentClub
) => {
  return (
    currentUser.id === currentClub.creatorId ||
    currentUser.subscriptionType === "admin"
  );
};

/**Utility function to return an array of clubs the user is an owner of
 * @params pass this function the current users ID and My clubs from
 *  the appropriate store e.g. (userStore.currentUser.id, groupStore.myClubs)
 * @returns array of clubs user has created
 */
export const getUsersOwnedClubs = (
  /**the id of the current user
   * @type number */
  userId, 
  /**the array of groups in the myClubs property of the user store
   * @type [group] */
  myClubs
  ) => {
  // check if is undefined
  if (myClubs === undefined) {
    return []
  }
  return myClubs.filter(club => club.creatorId === userId)
};


/**Utility function to return the number of members a given user has in all 
 * the clubs they have created.
 * @params pass this function the current users ID and My clubs from
 *  the appropriate store e.g. (userStore.currentUser.id, groupStore.myClubs)
 * @returns number of members over all clubs
 */
export const getTotalNumberOfMembers = (
  /**the id of the current user
   * @type number */
   userId, 
  /**the array of groups in the myClubs property of the user store
   * @type [group] */
   myClubs
  ) => {
  const ownedList = getUsersOwnedClubs(userId, myClubs);
  const count = ownedList.reduce((acc,club)=>{
    return acc + club.memberCount
  },0);
  return count;
};

/**Utility function to return the number of seats a user has purchased over all subscriptions 
 * @params pass this function the current user from the user store e.g. userStore.currentUser.
 * @returns total number of seats purchased by a user.
*/
export const getTotalNumberOfPurchasedSeats = (
  /**the current user
   * @type User */
  currentUser
  ) => {
  const count = currentUser.subscriptions.reduce((acc, sub)=>{
    return acc + sub.seats
  },0)
  return count;
};

/**Utility function to return the number of purchased seats that have not been assigned to a 
 * member.
 * @params pass this function the current users and My clubs from
 *  the appropriate store e.g. (userStore.currentUser, groupStore.myClubs)
 * @returns number of remaining seats
 */
export const getTotalRemainingSeats = (
  /**the current user
   * @type User */
  currentUser,
  /**the array of groups in the myClubs property of the user store
   * @type [group] */
  myClubs
) => {
  return getTotalNumberOfPurchasedSeats(currentUser) - getTotalNumberOfMembers(currentUser.id, myClubs)
};
