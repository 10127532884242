import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Fade from "@material-ui/core/Fade";

import FaceIcon from "@material-ui/icons/Face";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { pickColour } from "../../utils/color-picker";

const useStyles = makeStyles((theme) => ({
  avatar: {
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    marginLeft: 8,
    cursor: "pointer",
    fontFamily: '"Fredoka One", cursive',
    position: 'absolute',
    top: 6,
    right: 12,
    width: 35,
    height: 35,
    fontWeight: 600,
    fontSize: 18,
    backgroundColor: "#face13",
  },
  welcomeContainer: {
    borderRadius: 40,
    flexShrink: 100,
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: 40,
    top: 10,
    padding: 4,
    backgroundColor: "#5019c9",
  },
  welcomeText: {
    fontFamily: "'Fredoka One', cursive;",
    color: "#FFF",
    fontSize: 13,
    paddingLeft: 20,
    paddingRight: 20,
  },
  badge: {
    left: 2,
    top: 14,
    color: "#FFF",
  },
  listIcon: {
    minWidth: 40,
  },
}));

const AvatarDropdown = ({ username, id, hideWelcome }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenProfile = () => {
    setAnchorEl(null);
    window.parent.postMessage('gdc-show-profile', '*')
  };

  const handleLogout = () => {
    window.parent.postMessage("gdc-logout", "*")
  };

  if (!username) {
    return null;
  }

  const initial = username.trim().charAt(0);
  
  return (
    <>
      <Fade in={true} timeout={200}>
        <Avatar
          onClick={handleClick}
          className={classes.avatar}
          style={{ backgroundColor: pickColour(id) }}
        >
          {initial.toUpperCase()}
        </Avatar>
      </Fade>
      <Menu
        autoFocus={true}
        anchorEl={anchorEl}
        keepMounted
        transitionDuration={0}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenProfile} id="clubs-avatar-dropdown-profile-btn">
          <ListItemIcon className={classes.listIcon}>
            <FaceIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </MenuItem>
        <MenuItem onClick={handleLogout} id="clubs-avatar-dropdown-logout-btn">
          <ListItemIcon className={classes.listIcon}>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default AvatarDropdown
