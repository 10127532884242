const feathers = require("@feathersjs/feathers");
const rest = require("@feathersjs/rest-client");
const auth = require("@feathersjs/authentication-client");
const axios = require("axios")
axios.defaults.withCredentials = true;

export const client = feathers()

const urls = {
  development: "http://dev.gamefroot.com:3030",
  production: "https://api.gamefroot.com",
  staging: "https://api.staging.gamefroot.com",
};

export const url = urls[process.env.REACT_APP_GAMEFROOT_ENV];

const restClient = rest(url)

client.configure(auth({ storageKey: "feathers-jwt" }));

client.configure(restClient.axios(axios))

export const userService = client.service('user')
export const lessonRecordService = client.service('member-lesson-records')
export const groupService = client.service("groups");
export const groupCommentService = client.service("group-comments")
export const lessonService = client.service("lessons");
export const emailsService = client.service("emails");
export const seatService = client.service("seats")
export const memberService = client.service("members");

// export const progressService = client.service("progress");
// export const gcloudService = client.service("gcloud");
// export const versionService = client.service("versions");
// export const marketplaceService = client.service("marketplace");
// export const downloadService = client.service("download");
// export const packService = client.service("packs");
// export const attendanceService = client.service("attendance");
// export const eventService = client.service("events");
// export const notificationService = client.service("notifications");
// export const assetService = client.service("assets");
// export const authManagementService = client.service('auth-management')
// export const gameService = client.service('games');