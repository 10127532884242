import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import PulseLoader from 'react-spinners/PulseLoader'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import FrownIcon from "@material-ui/icons/SentimentDissatisfied";
import { makeStyles } from '@material-ui/styles'

import {
  useGroupStore
} from '../../state'

import ClubEntry from './ClubEntry'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  clubCard: {
    width: "100%"
  },
}))

const MyClubs = () => {
  const groupStore = useGroupStore()
  const classes = useStyles()

  useEffect(() => {
    groupStore.setClubFilter({ myClubs: '' })
    groupStore.filterClubs()
  }, [])

  const {
    filteredMyClubs,
    loadingClubs
  } = groupStore

  return (
    <div>
      <Grid className={classes.gridContainer} container >
        <Grid item>
        <Typography variant={'h1'}>
            My Clubs
        </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.gridContainer}>
        <Fade timeout={100} in={loadingClubs}>
          <div style={{ margin: 'auto', position: "relative", top: 10 }} >
            <PulseLoader width={180} color="rgb(67, 196, 255)" />
          </div>
        </Fade>

        {filteredMyClubs
          .map((club, i) => (
            <Grid item key={i} className={classes.clubCard}>
              <ClubEntry
                key={club.id}
                i={i}
                id={club.id}
                icon={club.name.substring(0, 1).toUpperCase() || "U"}
                name={club.name.length ? club.name : "Untitled Club"}
                organizer={club.organiserName}
                createdAt={club.createdAt}
                destination={club.location}
                groupMembers={club.groupMembers}
              />
            </Grid>
          ))}
      </Grid>

      {!loadingClubs && !filteredMyClubs.length && (
        <ListItem disableGutters>
          <ListItemIcon>
            <FrownIcon />
          </ListItemIcon>
          <ListItemText primary="No results for your search." />
        </ListItem>
      )}
    </div>
  )

}

export default observer(MyClubs)
