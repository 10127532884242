import {
  lessonService,
  lessonRecordService,
} from './client'

export const fetchLessons = () => lessonService.find()

export const fetchMemberRecords = (member_ids, game_id) => {
  return lessonRecordService.find({
    query: {
      member_id: {
        $in: Array.isArray(member_ids) ? member_ids : [member_ids]
      },
      ...(game_id && { game_id }),
      include: [
        {
          service: 'progress',
          as: 'progress',
        },
        {
          service: 'projects',
          as: 'project',
        },
        {
          service: 'attendance',
          as: 'attendance',
        },
        {
          service: 'lessons',
          as: 'lesson',
          attributes: {
            exclude: [
              'tutorial_snapshot',
              'contents_snapshot',
              'id'
            ]
          },
          include: {
            service: 'microcredentials',
            as: 'microcredential',
            include: {
              service: 'badges',
              as: 'badge'
            }
          }
        }
      ],
      flags: {
        calculateGroupProgress: true
      }
    }
  })
}

export const updateRecord = async (id, data) => {
  try {
    const res = await lessonRecordService.patch(id, data);
    return res;
  }
  catch (err) {
    console.log(err);
  }
}
