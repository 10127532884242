import Typography from "@material-ui/core/Typography";
import { motion } from "framer-motion";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/core/styles";

import clubBanner from "../../img/clubinfo2x.jpg"

const useStyles = makeStyles(theme => ({
  text: {
    color: "#FFF",
    fontFamily: "'Fredoka One', cursive;",
    WebkitFontSmoothing: "antialiased",
    fontSize: 15.5,
    paddingTop: 15
  },
  button: {
    color: "#fcea00",
    marginTop: 2,
    marginRight: 5,
    fontFamily: "'Fredoka One', cursive;",
    WebkitFontSmoothing: "antialiased"
  },
  background: {
    backgroundColor: "#348df8",
    boxShadow: "0 10px 20px 0 rgba(35, 50, 127, 0.3), 0 2px 8px 0 rgba(35, 50, 127, 0.25);",
    overflow: "hidden",
    borderRadius: 20,
    height: 130,
    width: '110%',
    marginTop: 25,
    '&:hover': {
      boxShadow: "0 10px 20px 0 rgba(35, 50, 127, 0.4), 0 2px 8px 0 rgba(35, 50, 127, 0.4);"
    }
  },
  circleImage: {
    boxShadow: "0 10px 20px 0 rgba(35, 50, 127, 0.3), 0 2px 8px 0 rgba(35, 50, 127, 0.25);",
    position: "relative",
    backgroundColor: '#348df8',
    zIndex: 1,
    top: -12,
    right: 25,
    borderRadius: "50%",
    height: 155,
    width: 155,
  },
  colorSplash: {
    backgroundColor: "#0ec9ff",
    borderRadius: "50%",
    height: 170,
    width: 170,
    position: "relative",
    bottom: 19,
    left: -10
  },
  icon: {
    color: "#FFF",
    fontSize: "2rem"
  },
  iconButton: { float: "right", marginRight: 5, marginTop: 5, padding: 7 }
}));

const variants = {
  closedEntry: {
    scale: 0
  },
  open: {
    x: -50,
    y: 0,
    scale: 1,
    opacity: [0, 0.7, 1],
    transition: { duration: 0.3, ease: "easeOut" }
  },
  closed: {
    x: 60,
    y: 30,
    scale: [1,0.3],
    opacity: 0,
    transition: { duration: 0.3, ease: "easeOut" }
  }
};

const AnimatedBanner = ({ animationState, handleClose, handleCreate, showCreate }) => {
  const classes = useStyles();

  const handleLearnMore = () => {
    window.parent.postMessage('gdc-open-faq', '*')
    window.parent.postMessage('gdc-close', '*')
  }

  return (
    <>
      <motion.div
        initial={
          animationState === "closedEntry" ? {
            x: 60,
            y: 80,
            scale: 0,
            opacity: 0
          } : {
            x: -50
          }
        }
        animate={animationState}
        variants={variants}
      >
        <div style={{ position: "absolute" }}>
          <Avatar
            className={classes.circleImage}
            classes={{root: classes.avatarRoot}}
            src={clubBanner}
          />
        </div>
        <Paper className={classes.background}>
          <Paper elevation={0} className={classes.colorSplash} />
          <Grid
            style={{ position: "relative", bottom: 170, paddingLeft: 180 }}
            container
          >
            <Grid item xs={11}>
              <Typography className={classes.text}>
                Use Groups to keep up with your members and their games. Perfect
                for teachers assigning classwork and tracking student progress,
                or community leaders running coding clubs. Gamefroot Groups keep
                you connected.
              </Typography>
              <Button onClick={handleLearnMore} className={classes.button}>Learn More</Button>
              {showCreate && <Button onClick={handleCreate} className={classes.button}>Create A Group</Button>}
            </Grid>
          </Grid>
        </Paper>
      </motion.div>
    </>
  );
};

export default AnimatedBanner;
