import React from 'react'
import { observer } from 'mobx-react-lite'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import {
  useGroupStore,
  useLessonStore,
  useUserStore,
} from '../../state'
import ClubMembers from './dashboard/ClubMembers'
import ClubLessons from './dashboard/ClubLessons'
import LessonDisplay from './dashboard/LessonDisplay'
import ClubDiscussion from './ClubDiscussion'
import { 
  userIsAffiliatedWithClub, 
  userIsClubAdmin 
} from '../../utils/access-utils'

const useStyles = makeStyles(theme => ({
  infoText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 12
  },
  dashboardBlock: {
    marginBottom: 48
  }
}))

const ClubDashboard = () => {
  const classes = useStyles();
  const lessonStore = useLessonStore();
  const userStore = useUserStore();
  const {
    currentClub,
    clubMembers,
  } = useGroupStore()
  const {
    selectedLessons,
  } = lessonStore
  const [isAffiliated, setIsAffiliated] = React.useState(false)
  const [isClubAdmin, setIsClubAdmin] = React.useState(false)

  React.useEffect(()=>{
    if(userStore.currentUser){
      setIsAffiliated(userIsAffiliatedWithClub(userStore.currentUser, currentClub.id))
      setIsClubAdmin(userIsClubAdmin(userStore.currentUser, currentClub))
    }
  },[userStore.currentUser])

  return (
    <Grid container spacing={6}>
      <Grid item xs={8}>
        <Typography variant={"h1"}>
          {currentClub.name}
        </Typography>

        <div className={classes.dashboardBlock}>
          <Typography style={{
            color: '#515873',
          }}>
            {currentClub.description}
          </Typography>
        </div>


        {isAffiliated &&
          <ClubDiscussion />
        }
      </Grid>

      {isAffiliated &&
        <Grid item xs={4}>
          {(isClubAdmin || selectedLessons) &&
            // <div className={class}>
              isClubAdmin ? (
                <ClubLessons />
              ) : (
                  <LessonDisplay />
                )
            // </div>
          }

          {Boolean(clubMembers) &&
            <div className={classes.dashboardBlock}>
              <ClubMembers />
            </div>
          }
        </Grid>
      }

    </Grid>
  )
}

export default observer(ClubDashboard)
