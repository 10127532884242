/** Format an ISO 8601 date string for display. */
const dateFormat = (utc, short = false) => {
  try {
    const o = new Date(utc);
    return o.toLocaleString(
      [],
      {
        dateStyle: short ? "short" : "full"
      }
    );
  } catch (err) {
    return "Unknown date";
  }
};

export default dateFormat;
