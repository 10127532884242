import React from 'react';
import { observer } from 'mobx-react-lite';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  useLessonStore,
  useGroupStore
} from '../../../state';

import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles(theme => ({
  assignButton: {
    width: '100%',
    fontFamily: "'Fredoka One', cursive;",
    textTransform: "none",
    fontSize: 16,
  },
  lessonContainer: {
    position: 'relative',
    marginBottom: 12
  },
  cancelButton: {
    position: 'absolute',
    top: -15,
    left: -15,
    backgroundColor: 'white',
    padding: 2,
    transition: "all 0.2s ease",
    "&:hover": {
      transform: 'scale(1.1)',
      backgroundColor: 'white',
      color: '#fc4b6c'
    },
  },
  dialogTitle: {
    color: "#ffffff",
    fontFamily: "'Fredoka One', cursive;",
    fontSize: "1.3rem",
  }
}));

const ClubLessons = () => {
  // hooks
  const classes = useStyles()
  const lessonStore = useLessonStore()
  const groupStore = useGroupStore()
  // local state
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openDialog,setOpenDialog] = React.useState(false)
  const [dialogActionID, setDialogActionID] = React.useState(null)
  // deconstruct global state
  const {
    lessons,
    selectedLessons,
    loadingLessons,
  } = lessonStore

  const {
    initialLoad,
    currentClub
  } = groupStore

  // event handlers
  const handleSelect = id => {
    if (!currentClub.lessonIds?.includes(id)) {
      lessonStore.addLesson(Number(id))
      groupStore.editClub(currentClub.id, { 
        lessonIds: Boolean(currentClub.lessonIds.length) ? [...currentClub.lessonIds, String(id)] : [String(id)]
      })
    }

    setAnchorEl(null)
  }

  const handleUnassign = id => {
    lessonStore.removeLesson(id);
    groupStore.editClub(currentClub.id, { lessonIds: currentClub.lessonIds.filter(lesson => lesson !== String(id)) });
    handleClose();
    handleDialogCleanUp();
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenDialog = id => {
    setOpenDialog(true);
    setDialogActionID(id);
  };

  const handleDialogCleanUp = () => {
    setOpenDialog(false);
    setDialogActionID(null);
  };

  const handleRequestOpenTutorial = (directory) => {
    const splitDirectoryArray = directory.split("/")
    const folderName = splitDirectoryArray[(splitDirectoryArray.length - 1)]
    window.parent.postMessage({message: "gdc-open-tutorial", tutorial: folderName}, "*");
  };

  // render statement
  return (
    <div style={{marginBottom: 48}}>
      {(!loadingLessons && Boolean(selectedLessons.length)) &&
        <Fade in={true} timeout={initialLoad ? 200 : 0}>
          <>
            <Typography variant={'h2'}>
              {`Active Lesson${selectedLessons.length > 1 ? 's' : ''}`}
            </Typography>

            {selectedLessons.map(lesson => {
              return (
              <div
                key={lesson.id}
                className={classes.lessonContainer}
              >
                <img 
                  key={lesson.id} 
                  src={lesson.image} 
                  alt="lesson info card" 
                  onClick={() => handleRequestOpenTutorial(lesson.directory)}
                  style={{cursor: "pointer"}}
                />
                <Button 
                  color='primary' 
                  style={{fontSize: "0.8rem"}}
                  onClick={() => handleOpenDialog(lesson.id)}
                >
                  unassign this lesson
                </Button>
              </div>  
            )})}
          </>
        </Fade>
      }

      <Button
        variant="contained"
        color="primary"
        onClick={e => setAnchorEl(e.currentTarget)}
        className={classes.assignButton}
      >
        ASSIGN A LESSON
      </Button>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {lessons &&
          lessons.filter(lesson => !selectedLessons.includes(lesson)).map(lesson => (
            <MenuItem
              key={lesson.id}
              onClick={() => handleSelect(lesson.id)}
            >
              <img src={lesson.image} alt="" />
            </MenuItem>
          ))
        }
      </Menu>

      <Dialog 
        open={openDialog}
        onClose={handleDialogCleanUp}
      >
        <DialogTitle style={{backgroundColor: "#3165ec"}} >
          <Typography className={classes.dialogTitle}>Unassign Lesson</Typography>
        </DialogTitle>
        <DialogContent>Are you sure you want to unassign this lesson?</DialogContent>
        <DialogActions>
          <Button 
            color='primary'
            onClick={() => handleUnassign(dialogActionID)}
          >unassign</Button>
          <Button
            color='primary'
            onClick={handleDialogCleanUp}
          >cancel</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default observer(ClubLessons);
