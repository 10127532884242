import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: "'Fredoka One', cursive;",
    display: 'flex'
  },
  button: {
    backgroundColor: "transparent !important"
  }
}));

const OutlinedButton = ({
  children,
  disabled,
  color = "#248df8",
  style,
  size = "medium",
  textStyle = {},
  className,
  ...props
}) => {
  const classes = useStyles();
  if (disabled) {
    color = "rgba(0, 0, 0, 0.26)";
  }
  return (
    // <motion.div
    //   whileTap={disabled ? {} : { scale: 0.95 }}
    //   whileHover={disabled ? {} : { scale: 1.05 }}
    // >
      <Button
        {...props}
        variant="outlined"
        size={size}
        disabled={disabled}
        className={classes.button}
        style={Object.assign(
          { border: `2px solid ${color}`, color },
          style && style
        )}
      >
        <span className={classes.text} style={textStyle}>
          {children}
        </span>
      </Button>
    //  </motion.div>
  );
};

export default OutlinedButton;
