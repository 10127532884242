import React, { useEffect, useState, lazy, Suspense } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/styles'

import {
  useGroupStore,
  useUserStore,
} from '../state'

import Loading from '../components/common/Loading'
import AppBar from '../components/common/AppBar'
import FindClubs from '../components/clubs/FindClubs'
import MyClubs from '../components/clubs/MyClubs'
import { userHasClubsAdminAccess } from '../utils/access-utils'
const CreateClub = lazy(() => import('../components/clubs/CreateClub'))

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 48px)",
    backgroundColor: "white"
  },
  container: {
    marginTop: 48,
    paddingTop: 26,
    maxWidth: 860,
    width: "100%",
    height: "100%",
  },
}))

const Home = () => {
  const [activeTab, setActiveTab] = useState('Find Clubs')
  const [tabs,setTabs] = useState(['Find Clubs'])
  const userStore = useUserStore()
  const groupStore = useGroupStore()
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    let tempTabs = ['Find Clubs']
    if (groupStore.myClubs.length) { tempTabs.push('My Clubs') }
    if (userHasClubsAdminAccess(userStore.currentUser)) { tempTabs.push('Create Club') }
    setTabs(tempTabs)
  }, [groupStore.myClubs, userStore.currentUser ]);

  return (
    <>
      <AppBar
        isHome={true}
        onChange={val => setActiveTab(val)}
        value={activeTab}
        tabs={tabs}
      />
      <div className={classes.root}>
        <Container className={classes.container}>
          {activeTab === 'Find Clubs' &&
            <FindClubs
              handleChangeTab={tab => setActiveTab(tab)}
            />
          }

          {activeTab === 'My Clubs' &&
            <MyClubs />
          }

          {activeTab === 'Create Club' &&
            <Suspense fallback={<Loading />}>
              <CreateClub
                groupStore={groupStore}
                userStore={userStore}
                history={history}
              />
            </Suspense>
          }
        </Container>
      </div>
    </>
  )
}

export default observer(Home)
