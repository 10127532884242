import React from 'react';
import { observer } from 'mobx-react-lite';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useUserStore } from '../../../state';
import Typography from "@material-ui/core/Typography";
import { ClipLoader } from 'react-spinners';


const useStyles = makeStyles(theme => ({
  padded: {
    paddingBottom: 16,
  },
}));

/**
 * REACT COMPONENT: displays the number of seats for a paying user broken down into the 
 * number of purchased seats, the number of seats currently in use and the number of purchased seats 
 * remaining not in use.
 */
const SeatsWidget = observer(() => {
  const classes = useStyles();
  const userStore = useUserStore();

  // local state
  const [renderWidget, setRenderWidget] = React.useState(false);

  // populate local state
  React.useEffect(() => {
    const permittedSubTypes = ["edu", "admin"];
    if (permittedSubTypes.includes(userStore.currentUser.subscriptionType)) {
      setRenderWidget(true);
      // ensure global state is populated to render
      if (!userStore.seatsPurchased) { userStore.getSeatsPurchased(); }
      if (!userStore.seatsUsed) { userStore.getSeatsUsed(); }
    }
    return () => {
      setRenderWidget(false);
    }
  }, [userStore.currentUser]);

  // refresh seat count
  React.useEffect(async () => {
    if (userStore.seatCountNeedsRefresh) {
      await userStore.getSubscription();
      userStore.getSeatsPurchased();
      userStore.getSeatsUsed();
      userStore.seatCountNeedsRefresh = false;
    }
  }, [userStore.seatCountNeedsRefresh])

  return (
    <>
      {renderWidget &&
        <div>
          <Typography variant={'h2'}>Seats Breakdown</Typography>
          {userStore.seatCountNeedsRefresh
            ?
              <div 
                style={{ 
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <ClipLoader
                  loading={userStore.seatCountNeedsRefresh}
                  size={25}
                  color={'#0085ff'}
                />
                <Typography style={{color: '#0085ff', marginLeft: "1rem"}}>
                  Refreshing
                </Typography>
              </div>
            :
              <>
                <Typography>Total Purchased Seats: {userStore.seatsPurchased}</Typography>
                <Typography>Total Used Seats: {userStore.seatsUsed}</Typography>
                <Typography className={classes.padded}>Total Seats Remaining: {userStore.seatsPurchased - userStore.seatsUsed}</Typography>
              </>
          }
          <Typography className={classes.padded}>
            These figures are a breakdown of all purchased subscriptions and active clubs 
            over your whole user account.
          </Typography>
        </div>
      }
    </>
  )
});

export default SeatsWidget;