import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import TextField from "@material-ui/core/TextField"
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'

import {
  useCommentStore
} from '../../../state'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: 12,
    marginBottom: 12
  },
  textButton: {
    fontFamily: "'Fredoka One', cursive;",
    textTransform: "none",
    fontSize: 12,
    marginRight: 12
  },
}))

const EditComment = ({
  body,
  id,
  parentId,
  cancelEdit
}) => {
  const commentStore = useCommentStore()
  const classes = useStyles()

  useEffect(() => {
    commentStore.updateEdit(body)
  }, [])

  const handleSubmit = () => {
    commentStore.editComment(id, parentId)
    cancelEdit()
  }

  return (
    <div style={{ marginTop: 12 }}>
      <TextField
        value={commentStore.editBody}
        onChange={e => commentStore.updateEdit(e.target.value)}
        rows={4}
        multiline
        fullWidth
      />

      <Grid container justify='flex-end' className={classes.buttonContainer}>
        <Button
          className={classes.textButton}
          color='primary'
          onClick={cancelEdit}
        >
          CANCEL
        </Button>

        <Button
          variant='contained' color='primary' className={classes.textButton}
          onClick={handleSubmit}
        >
          SAVE CHANGES
        </Button>
      </Grid>
    </div>
  )
}

export default observer(EditComment)
