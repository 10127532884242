import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import dateFormat from "../../../utils/date-format"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { makeStyles } from '@material-ui/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { pickColour } from '../../../utils/color-picker'
import NewComment from './NewComment'
import EditComment from './EditComment'

import {
  useUserStore,
  useGroupStore,
} from '../../../state'
import { userIsClubAdmin } from '../../../utils/access-utils'


const Comment = ({
  body,
  author,
  i,
  id,
  group_id,
  parent_comment_id,
  createdAt,
  replyTo,
  setReplyTo,
  focusComment,
  setFocusComment,
  onDelete,
  isParent
}) => {
  const useStyles = makeStyles(theme => ({
    iconContainer: {
      width: isParent ? 40 : 25,
      height: isParent ? 40 : 25,
      padding: 0,
      marginRight: 12,
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    iconText: {
      fontFamily: "Fredoka One, cursive",
      fontSize: isParent ? 25 : 16,
      textTransform: "capitalize",
      color: "white",
      fontWeight: 100,
    },
    commentContainer: {
      marginTop: 12,
      marginLeft: isParent ? 0 : 55,
      width: isParent ? "100%" : "calc(100% - 55px)"
    },
    textButton: {
      fontFamily: "'Fredoka One', cursive;",
      textTransform: "none",
      fontSize: 16,
    }
  }))

  const userStore = useUserStore()
  const classes = useStyles()
  const groupStore = useGroupStore();

  const [isClubAdmin, setIsClubAdmin] = React.useState(false)

  React.useEffect(()=>{
    setIsClubAdmin(userIsClubAdmin(userStore.currentUser, groupStore.currentClub))
  },[userStore.currentUser])

  const [anchorEl, setAnchorEl] = useState(undefined)
  const [editing, setEditing] = useState(false)

  const icon = author.username.substring(0, 1).toUpperCase()
  const date = createdAt ? dateFormat(createdAt, true) : 'just now'

  const handleDelete = () => {
    setAnchorEl(undefined)
    onDelete({ id, parentId: parent_comment_id })
  }

  const handleEdit = () => {
    setAnchorEl(undefined)
    setEditing(true)
    setFocusComment(id)
  }

  const handleCancel = () => {
    setFocusComment(null)
    setEditing(false)
  }

  return (
    <>
      {focusComment === id && editing ? (
        <EditComment
          body={body}
          id={id}
          parentId={parent_comment_id}
          cancelEdit={handleCancel}
        />
      ) : (
          <Grid container className={classes.commentContainer} style={{ marginTop: 12 }}>
            <Grid
              item
              className={classes.iconContainer}
              style={{ backgroundColor: pickColour(i, true) }}
            >
              <Typography className={classes.iconText}>
                {icon}
              </Typography>
            </Grid>

            <Grid item style={{ flex: 1 }}>
              <Grid container item direction="row" align="center">
                <Typography variant='subtitle1'>{author.username}</Typography>
                <Typography style={{ marginLeft: 8, marginRight: 8 }}>&#183;</Typography>
                <Typography variant='subtitle2'>{date}</Typography>
              </Grid>
              <Typography>{body}</Typography>
              {isParent &&
                <Button
                  className={classes.textButton}
                  color="primary"
                  onClick={() => setReplyTo(id)}
                >
                  REPLY
                </Button>
              }
            </Grid>

            {(author.id === userStore.currentUser.id ||
              isClubAdmin) &&
              <>
                <Grid item>
                  <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                    <MoreVertIcon  />
                  </IconButton>
                </Grid>

                <Menu
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(undefined)}
                >
                  <MenuItem onClick={handleEdit}>
                    Edit
                  </MenuItem>

                  <MenuItem onClick={handleDelete}>
                    Delete
                  </MenuItem>
                </Menu>
              </>
            }
          </Grid >
        )
      }

      {
        replyTo === id &&
        <NewComment
          context='reply'
          groupId={group_id}
          parentId={id}
          closeReply={() => setReplyTo(null)}
        />
      }
    </>
  )
}

export default observer(Comment)
