import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

import { useGroupStore } from '../../state'

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    height: 28,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 350,
    backgroundColor: '#628ae5',
    borderRadius: 20,
    alignSelf: 'center',
    flex: 1
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "#FFF",
    fontWeight: 500,
    height: 8,
  },
  icon: {
    color: "#FFF",
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
}))

const GroupSearch = () => {
  const classes = useStyles()
  const groupStore = useGroupStore()

  const handleChange = (e) => {
    e.preventDefault();
    groupStore.setSearch(e.target.value);
    groupStore.onSearch();
  }

  return (
    <Paper className={classes.root}>
      <SearchIcon className={classes.icon} />

      <InputBase className={classes.input}
        placeholder="Search for a group..."
        value={groupStore.search}
        onChange={handleChange}
      />
    </Paper>
  )
}

export default observer(GroupSearch)
