import { makeAutoObservable } from 'mobx'

import {
  fetchCommentsByGroup,
  createComment,
  updateComment,
  deleteComment
} from '../api/comments'

class CommentStore {
  constructor() {
    makeAutoObservable(this)
  }

  INITIAL_FETCH_SIZE = 20
  EXTENSION_FETCH_SIZE = 20
  MAX_COMMENT_LENGTH = 1000

  // <--- Observables --->
  size = this.INITIAL_FETCH_SIZE

  comments = []
  totalResults = 0

  body = ''
  editBody = ''
  remainingCharacters = this.MAX_COMMENT_LENGTH

  loadingComments = false
  loadingCommentsError = undefined
  outOfComments = false

  submittingComment = false

  groupId = 0

  // <--- Actions --->
  updateBody(body) {
    if (body.length <= this.MAX_COMMENT_LENGTH) {
      this.body = body
      this.remainingCharacters = this.MAX_COMMENT_LENGTH - body.length
    }
  }

  updateEdit(edit) {
    if (edit.length <= this.MAX_COMMENT_LENGTH) {
      this.editBody = edit
    }
  }

  // <--- Flow --->
  async initialFetch(groupId) {
    this.groupId = groupId
    this.comments = []
    this.loadingComments = true
    this.loadingCommentsError = undefined
    this.outOfComments = false

    try {
      const { data, flags } = await fetchCommentsByGroup({ groupId, limit: this.INITIAL_FETCH_SIZE })

      this.comments = data
      this.totalResults = data.length

      if (this.totalResults < this.size) this.outOfComments = true
      if (flags && flags.endOfResults) this.outOfComments = true
    } catch (err) {
      this.loadingCommentsError = 'Failed to load more comments.  Please refresh the page and try again'
      this.outOfComments = true
      console.error(err)
    } finally {
      this.loadingComments = false
    }
  }

  async extendComments(amount = this.EXTENSION_FETCH_SIZE) {
    this.loadingCommentsError = undefined
    this.loadingComments = true
    try {
      const { data, flags } = await fetchCommentsByGroup(this.groupId, amount, this.size)
      if (!data.length) {
        this.loadingGamesError = "Out of Comments"
        this.outOfComments = true
        return
      }

      this.comments = [...this.comments, ...data]
      this.totalResults += data.length
      this.size += amount

      if (this.totalResults < this.size) this.outOfComments = true
      if (flags && flags.endOfResults) this.outOfComments = true

    } catch (err) {
      this.loadingCommentsError = 'Failed to load more comments.  Please refresh the page and try again'
      this.outOfComments = true
      console.error(err)
    } finally {
      this.loadingComments = false
    }
  }

  async createComment({ groupId, parentId, username, id }) {
    if (this.body.length) {
      this.submittingComment = true

      try {
        const commentId = await createComment(groupId, this.body, parentId)

        const comment = {
          author: {
            username,
            id
          },
          body: this.body,
          userId: id,
          ...(parentId && { parent_comment_id: parentId }),
          group_id: groupId,
          id: commentId
        }

        this.updateBody('')
        this.totalResults += 1

        if (parentId) {
          const parentComment = this.comments.find(comment => comment.id === parentId)

          parentComment.children ? parentComment.children.push(comment) : parentComment.children = [comment]
        } else {
          this.comments.unshift(comment)
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.submittingComment = false
      }
    }
  }

  async editComment(id, parentId) {
    this.submittingComment = true

    try {
      await updateComment(id, this.editBody)

      if (parentId) {
        const parentIndex = this.comments.findIndex(parent => parent.id === parentId)

        const child = this.comments[parentIndex].children.find(child => child.id === id)

        child.body = this.editBody
      } else {
        const comment = this.comments.find(comment => comment.id === id)

        comment.body = this.editBody
      }

      this.updateEdit('')
    } catch (err) {
      console.error(err)
    } finally {
      this.submittingComment = false
    }
  }

  async deleteComment({ id, parentId }) {
    const toDelete = [id]

    if (!parentId) { // Add children comments to delete queue
      const comment = this.comments.find(comment => (comment.id === id))
      comment.children && toDelete.push(...comment.children.map(child => child.id))
    }

    try {
      const promises = toDelete.map(async id => deleteComment(id))

      await Promise.all(promises)

      if (!parentId) { // Clean up comment array
        this.comments = this.comments.filter(comment => comment.id !== id)
      } else {
        const parent = this.comments.find(parent => parent.id === parentId)
        parent.children = parent.children.filter(child => child.id !== id)
      }

      this.totalResults -= toDelete.length
    } catch (err) {
      console.log(err)
    }
  }
}

export default CommentStore
