import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import { useUserStore } from '../../state'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import BackIcon from '@material-ui/icons/ArrowBackRounded'
import { IconButton } from '@material-ui/core'
import Button from "@material-ui/core/Button";

import AvatarDropdown from './AvatarDropdown'

import LogoLarge from '../../img/gamefroot.png'

const useStyles = makeStyles(theme => ({
  appBar: {
    height: 50,
    backgroundColor: "#3165ec",
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  logo: {
    height: 40,
    position: 'absolute',
    top: 5,
    left: 8,
  },
  containerCenter: {
    maxWidth: 860,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,

  },
  avatar: {
    position: 'absolute',
    top: 3,
    right: 0,
  },
  iconButton: {
    padding: 6,
    color: "#FFF",
    marginLeft: 55,
    marginRight: 25
  },
  container: {
    maxWidth: 900
  },
  tab: {
    fontFamily: "'Fredoka One', cursive;",
    minWidth: "inherit",
    width: "inherit",
    textTransform: "none",
    fontSize: 16,
    padding: 0,
    marginRight: 20
  },
  tabIndicator: {
    backgroundColor: "#fdf801",
    height: 4
  },
  textColorInherit: {
    opacity: 1
  },
  welcomeContainer: {
    borderRadius: 40,
    flexShrink: 100,
    display: "flex",
    alignItems: "center",
    padding: 13,
    backgroundColor: "#5019c9"
  },
  welcomeText: {
    fontFamily: "'Fredoka One', cursive;",
    color: "#FFF",
    fontSize: 13,
    paddingLeft: 20,
    paddingRight: 20
  },
  login: {
    fontFamily: "'Fredoka One', cursive",
    color: "#FFF",
    backgroundColor: "#64dd17",
    "&:hover": {
      backgroundColor: "#64dd17",
    },
  },
}));

const TopBar = (props) => {
  const classes = useStyles();
  const userStore = useUserStore();
  const history = useHistory();

  const StyledTab = props => (
    <Tab
      classes={{
        textColorInherit: classes.textColorInherit,
        root: classes.tab
      }}
      {...props}
    />
  );

  const { currentUser } = userStore

  const handleLogin = () => {
    window.parent.postMessage('gdc-login', '*')
  };

  const handleBack = () => {
    if(props.isHome){
      window.parent.postMessage('gdc-close', '*');
    } else {
      history.goBack();
    }
  };

  return (
    <AppBar className={classes.appBar} position='fixed'>
      <img src={LogoLarge} className={classes.logo} alt='Gamefroot Logo' />

      {/* {!props.isHome ? (
        <Link to={'/'}>
          <IconButton className={classes.iconButton}>
            <BackIcon style={{ fontSize: "2rem" }} />
          </IconButton>
        </Link>
      ) : (
          <IconButton onClick={() => window.parent.postMessage('gdc-close', '*')} className={classes.iconButton}>
            <BackIcon style={{ fontSize: "2rem" }} />
          </IconButton>
        )} */}

      <IconButton onClick={handleBack} className={classes.iconButton}>
        <BackIcon style={{ fontSize: "2rem" }} />
      </IconButton>

      <div className={classes.containerCenter}>
        <Tabs
          className={classes.tabContainer}
          classes={{ indicator: classes.tabIndicator }}
          style={{ overflow: "visible" }}
          value={props.value}
        >
          {props.tabs.map((tab, i) => (
            <StyledTab
              label={tab}
              onClick={() => props.onChange(tab)}
              value={tab}
              key={i}
            />

          ))}
        </Tabs>
      </div>

      {/* Sneaky hidden icon to keep rest of appbar centered */}
      <BackIcon className={classes.iconButton} style={{ fontSize: "2rem", visibility: 'hidden' }} />

      <div className={classes.avatar}>
        {
          (currentUser.id) ? (
            <>
              <AvatarDropdown hideWelcome {...currentUser} />
            </>
          ) : (
              <div style={{ position: "absolute", right: 10, top: 6 }}>
                <motion.div whileTap={{ scale: 0.95 }}>
                  <Button
                    onClick={handleLogin}
                    className={classes.login}
                    variant="contained"
                  >
                    Login
              </Button>
                </motion.div>
              </div>
            )}
      </div>
    </AppBar>
  )
}

export default observer(TopBar)