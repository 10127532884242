import { makeAutoObservable } from 'mobx'

import {
  fetchGroupsPublic,
  fetchMembershipsWithGroups,
  fetchGroup,
  fetchGroupMembers,
  createGroup,
  editGroup,
  createMember,
  updateMembersRoles
} from '../api/groups'

import {
  createUser,
  createSeat
} from '../api/user'

import {
  fetchMemberRecords
} from '../api/lessons'

import {
  fetchProjectsByUser
} from '../api/games'

const INITIAL_FETCH_AMOUNT = 20
const EXTENSION_FETCH_SIZE = 20

class GroupStore {
  initialLoad = true;
  size = INITIAL_FETCH_AMOUNT;

  loadingClubs = false;
  clubsError;
  clubFilter = {
    findClubs: "",
    myClubs: "",
  };
  filteredPublicClubs = [];
  filteredMyClubs = [];
  publicClubs = [];
  myClubs = [];

  creatingClub = false;
  createClubError;
  createClubSuccess;

  /* Club */
  loadingClub = false;
  currentClub;
  outOfClubs = false;
  search = "";
  lastSearch = "";
  mustSearch = false;

  loadingMembers = false;
  membersError;
  currentClubMembers = [];
  myClubMembers = [];

  loadingGames = false;
  gamesError;
  gameFilter = "members";
  currentClubGames = [];
  currentClubDrafts = [];
  filteredClubGames = [];
  showDrafts = false;

  editingClub = false;
  editClubError;
  editClubSuccess;

  changingClubStatus = false;
  changeClubStatusSuccess;
  changeClubStatusError;

  editingClubRoles = false;
  editClubRolesSuccess;
  editClubRolesError;

  removingMembers = false;
  removeMembersError;
  removeMembersSuccess;

  authorizingPayment = false;
  authorizingPaymentSuccess;
  authorizingPaymentError;

  creatingMember = false;
  creatingMemberError;
  creatingMemberSuccess;

  constructor() {
    makeAutoObservable(this);
  }

  // <-- Computed --->
  get existingOrganisations() {
    return [
      ...new Set(
        this.myClubs
          .filter((club) => ["admin"].includes(club.role))
          .map((club) => club.organiserName)
      ),
    ];
  }

  get clubAdmins() {
    return this.currentClubMembers.filter((member) => member.role === "admin");
  }

  get clubMembers() {
    return this.currentClubMembers.filter((member) => member.role === "member");
  }

  get freeLimit() {
    return this.currentClubMembers.length === 3;
  }

  // <--- Actions --->

  setClubFilter(query) {
    this.clubFilter = Object.assign(this.clubFilter, query);
  }

  filterClubs() {
    const predicate = (key) => (club) =>
      club.name.toLowerCase().includes(this.clubFilter[key].toLowerCase()) ||
      club.location.toLowerCase().includes(this.clubFilter[key].toLowerCase());
    this.filteredPublicClubs = this.publicClubs.filter(predicate("findClubs"));
    this.filteredMyClubs = this.myClubs.filter(predicate("myClubs"));
  }

  resetStatus(type) {
    return {
      club: () => {
        this.currentClubDrafts = undefined;
        this.initialLoad = true;
        this.showDrafts = false;
        this.currentClubGames = [];
        this.currentClubMembers = [];
        this.expiredClub = undefined;
        this.clubError = undefined;
      },
      clubs: () => {
        this.clubsError = undefined;
      },
      roles: () => {
        this.editClubRolesSuccess = undefined;
        this.editClubRolesError = undefined;
      },
      edit: () => {
        this.editClubSuccess = undefined;
        this.editClubError = undefined;
      },
      status: () => {
        this.changingClubStatus = false;
        this.changeClubStatusError = undefined;
        this.changeClubStatusSuccess = undefined;
      },
      create: () => {
        this.createClubSuccess = undefined;
        this.createClubError = undefined;
      },
      payment: () => {
        this.authorizingPaymentError = undefined;
        this.authorizingPaymentSuccess = false;
      },
      games: () => {
        this.gamesError = undefined;
      },
      members: () => {
        this.membersError = undefined;
      },
      createMember: () => {
        this.creatingMemberError = undefined;
        this.creatingMemberSuccess = undefined;
      },
      remove: () => {
        this.removeMembersError = undefined;
        this.removeMembersSuccess = undefined;
      },
    }[type]();
  }

  checkCacheForClub(id) {
    id = parseInt(id, 10);
    const searchPublic = this.publicClubs.find((club) => club.id === id);
    const searchPrivate = this.myClubs.find((club) => club.id === id);
    return searchPrivate || searchPublic;
  }

  filterGames() {
    const games = this.showDrafts
      ? this.currentClubDrafts
      : this.currentClubGames;
    const admins = this.currentClubMembers
      .filter((member) => member.role === "admin")
      .map((admin) => admin.userId);
    if (this.gameFilter === "members") {
      this.filteredClubGames = admins.length
        ? games.filter((game) => !admins.includes(game.owner))
        : games;
    } else if (this.gameFilter === "admin") {
      this.filteredClubGames = admins.length
        ? games.filter((game) => admins.includes(game.owner))
        : [];
    } else {
      this.filteredClubGames = games;
    }
  }

  toggleShowDrafts(value) {
    this.showDrafts = value;
    if (!this.currentClubDrafts && this.showDrafts) {
      this.getClubGames();
    } else {
      this.filterGames();
    }
  }

  setGameFilter(data) {
    this.gameFilter = data;
  }
  
  setSearch(val) {
    this.search = val;
  }
  
  onSearch() {
    if (this.search !== this.lastSearch) {
      if (this.loadingClubs) {
        this.mustSearch = true;
      } else {
        this.lastSearch = this.search;
        this.publicClubs = [];
        this.outOfClubs = false;
      }
    }
  }

  // <--- Flow --->

  async extend() {
    this.loadingClubs = true;
    try {
      const { data } = await fetchGroupsPublic({
        limit: EXTENSION_FETCH_SIZE,
        offset: this.publicClubs.length,
        searchQuery: this.search,
      });
      if(this.mustSearch){
        this.mustSearch = false;
        this.onSearch();
        this.extend();
        return
      }
      this.publicClubs = [...this.publicClubs, ...data];
      this.filterClubs();
      if (data.length < EXTENSION_FETCH_SIZE) {
        this.outOfClubs = true;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingClubs = false;
    }
  }


  async getClubsUser(userId) {
    this.loadingClubs = true;
    this.resetStatus("clubs");
    try {
      const { data: memberships } = await fetchMembershipsWithGroups(userId);

      const clubs = memberships.map(({ group, ...membership }) => ({
        ...membership,
        member_id: membership.id,
        ...group,
      }));

      this.myClubs = clubs.filter(
        (club) => !["deleted", "archived"].includes(club.status)
      );
      this.filteredMyClubs = this.myClubs;

      const remainingClubs = this.publicClubs.filter(
        (club) => !this.myClubs.map((club) => club.id).includes(club.id)
      );

      this.filteredPublicClubs = remainingClubs;
      this.publicClubs = remainingClubs;
    } catch (err) {
      this.clubsError = err;
    } finally {
      this.loadingClubs = false;
    }
  }

  async getClubById(id) {
    this.loadingClub = true;
    this.resetStatus("club");
    const club = this.checkCacheForClub(id);
    // const club = null;
    if (club) {
      this.currentClub = club;
      this.loadingClub = false;
    } else {
      try {
        const club = await fetchGroup(id);
        this.currentClub = club;
      } catch (err) {
        console.log(err);
        this.clubError = err;
      } finally {
        this.loadingClub = false;
      }
    }
  }

  async getClubMembers(includeRemoved) {
    this.loadingMembers = true;
    this.resetStatus("members");
    try {
      const { data: members } = await fetchGroupMembers(
        this.currentClub.id,
        includeRemoved
      );

      const flattenedMembers = members.map(({ user, ...member }) => ({
        ...member,
        ...user,
      }));

      if (includeRemoved) {
        const removed = (member) => member.role === "removed";
        this.currentClubMembers = flattenedMembers.filter(
          (member) => !removed(member)
        );
        this.removedClubMembers = flattenedMembers.filter(removed);
      } else {
        this.currentClubMembers = flattenedMembers;
      }

      const { data } = await fetchMemberRecords(
        this.currentClubMembers.map((member) => member.id)
      );

      data.forEach((record) => {
        this.currentClubMembers.find(
          (member) => member.id === record.member_id
        ).record = record;
      });
    } catch (err) {
      this.membersError = err;
    } finally {
      this.loadingMembers = false;
    }
  }

  async getMemberRecords() {
    const { data } = await fetchMemberRecords(
      this.currentClubMembers.map((member) => member.id)
    );

    data.forEach((record) => {
      this.currentClubMembers.find(
        (member) => member.id === record.member_id
      ).record = record;
    });
  }

  async getClubGames() {
    this.loadingGames = true;
    this.filteredClubGames = [];
    const ids =
      this.currentClubMembers &&
      this.currentClubMembers.map((member) => member.userId);
    this.resetStatus("games");
    this.resetStatus("status");
    if (this.currentClubMembers.length) {
      try {
        const result = await fetchProjectsByUser(
          ids,
          this.showDrafts,
          !this.showDrafts
        );
        if (this.showDrafts) {
          this.currentClubDrafts = result;
        } else {
          this.currentClubGames = result;
        }

        this.filterGames(this.gameFilter);
      } catch (err) {
        this.gamesError = err;
        console.error(err);
      } finally {
        this.loadingGames = false;
      }
    }
  }

  async createClub(data) {
    this.creatingClub = true;
    this.resetStatus("create");
    try {
      this.createClubSuccess = await createGroup(data);
      this.createClubSuccess.role = "admin";
      this.myClubs.unshift(this.createClubSuccess);
      this.filteredPublicClubs.unshift(this.createClubSuccess);
      // this.filterClubs();
    } catch (err) {
      this.createClubError = err;
      console.error(err);
    } finally {
      this.creatingClub = false;
    }
  }

  async editClub(id, data) {
    this.editingClub = true;
    this.resetStatus("edit");
    try {
      const updatedClub = await editGroup(id, data);
      this.editClubSuccess = updatedClub;
      this.currentClub = Object.assign(this.currentClub, updatedClub);
      const i =
        this.myClubs.indexOf((club) => club.id === id) ||
        this.publicClubs.indexOf((club) => club.id === id);
      this.myClubs = Object.assign([], this.myClubs, {
        [i]: Object.assign(this.currentClub, updatedClub),
      });
      // this.filterClubs();
    } catch (err) {
      this.editClubError = err;
      console.error(err);
    } finally {
      this.editingClub = false;
    }
  }

  async createMember(payload, subscriptionId) {
    this.resetStatus("createMember");
    this.creatingMember = true;
    try {
      const user = await createUser(
        Object.assign(
          { ...payload, features: "code_club" },
          Number.isInteger(subscriptionId) && { subscriptionType: "pro" }
        )
      );
      const member = await createMember(user.id, this.currentClub.id);
      const memberData = { ...user, ...member };
      this.currentClubMembers.unshift(memberData);
      if (Number.isInteger(subscriptionId)) {
        const seat = await createSeat({
          userId: user.id,
          subscriptionId,
        });
        return seat;
      }
    } catch (err) {
      this.createMemberError = err;
      throw err;
    } finally {
      this.creatingMember = false;
    }
  }

  async updateGrades(gradeLists) {
    const incomingLists = Object.keys(gradeLists);

    await this.editClub(this.currentClub.id, gradeLists);

    incomingLists.forEach((list) => {
      this.currentClub[list] = gradeLists[list];
    });
  }

  async changeClubStatus(status) {
    this.changingClubStatus = true;
    const id = this.currentClub.id;
    this.resetStatus("status");
    try {
      this.changeClubStatusSuccess = await editGroup(id, { status });
      if (["deleted", "archived"].includes(status)) {
        // await changeEventStatusByClub(id, status);
        this.myClubs = this.myClubs.filter((club) => club.id !== id);
        this.publicClubs = this.publicClubs.filter((club) => club.id !== id);
        // this.filterClubs();

        // remove deleted clubs members
        this.removeMembers({
          removed: this.currentClubMembers,
          members: []
        })
      }
    } catch (err) {
      this.changeClubStatusError =
        "Failed to delete club, please refresh and try again.";
      console.error(err);
    } finally {
      this.changingClubStatus = false;
    }
  }

  async editClubRoles({ admins, members }) {
    this.editingClubRoles = true;
    this.resetStatus("roles");
    const adminChanges = admins.filter((admin) => {
      return (
        this.currentClubMembers.find((member) => member.userId === admin.userId)
          .role !== "admin"
      );
    });
    const memberChanges = members.filter((member) => {
      return (
        this.currentClubMembers.find(
          (previousMember) => previousMember.userId === member.userId
        ).role !== "member"
      );
    });
    try {
      if (adminChanges.length) {
        await updateMembersRoles(adminChanges, "admin");
        this.editClubRolesSuccess = "Successfully updated membership roles";
      }
      if (memberChanges.length) {
        await updateMembersRoles(memberChanges, "member");
        this.editClubRolesSuccess = "Successfully updated membership roles";
      }
    } catch (err) {
      this.editClubRolesError =
        "Failed to update club roles. Please try again.";
      console.error(err);
    }
    this.editingClubRoles = false;
  }

  async removeMembers({ removed, members }, subscriptionId) {
    this.resetStatus("remove");
    this.removingMembers = true;
    const toRemove = removed.filter((member) => member.role === "member");
    const toUndoRemove = members.filter((member) => member.role === "removed");
    const sumChanges = toRemove.concat(toUndoRemove).length;
    try {
      if (toRemove.length) {
        await updateMembersRoles(toRemove, "removed");
      }
      if (toUndoRemove.length) {
        await updateMembersRoles(toUndoRemove, "member");
        // reinstate seats for reactivated member
        // NOTE: assumes seat record was deleted when changed to removed.
        toUndoRemove.forEach(async (member) => {
          await createSeat({
            userId: member.userId,
            subscriptionId
          });
        })
      }
      const getId = (member) => member.userId;
      const setRole = (role) => (member) => ({ ...member, role });
      const isUnchanged = (changes) => (member) =>
        !changes.map(getId).includes(member.userId);
      this.removedClubMembers = [
        ...this.removedClubMembers.filter(isUnchanged(toUndoRemove)),
        ...toRemove.map(setRole("removed")),
      ];
      this.currentClubMembers = [
        ...this.currentClubMembers.filter(isUnchanged(toRemove)),
        ...toUndoRemove.map(setRole("member")),
      ];
      this.removeMembersSuccess = `Successfully updated 
        ${sumChanges > 1 ? "members roles" : "member role"}.`;
    } catch (err) {
      this.removeMembersError = `Failed to ${
        sumChanges > 1 ? "members roles" : "member role"
      }. 
       Please refresh and try again.`;
    } finally {
      this.removingMembers = false;
    }
  }

  updateIndividualMemberRecord(passedData) {
    // copy array and update target record only
    this.currentClubMembers = this.currentClubMembers.map((member) => {
      if (member.id === passedData.member_id) {
        // copy data to be updated
        let newdata = member;
        // updated changed properties
        newdata.record.feedback = passedData.feedback;
        newdata.record.grade = passedData.grade;
        newdata.record.passedAt = passedData.passedAt;
        newdata.record.status = passedData.status;
        console.log("DEBUG: modified newdata is:", newdata);
        // return updated data
        return newdata;
      } else {
        // return data
        return member;
      }
    });
  }
}

export default GroupStore;
