import React, { createContext, useContext, useState } from 'react';

import UserStore from "./UserStore";
import LessonStore from "./LessonStore"
import GroupStore from "./GroupStore"
import CommentStore from './CommentStore'

const UserContext = createContext(null);
const LessonContext = createContext(null);
const GroupContext = createContext(null)
const CommentContext = createContext(null)

export const UserProvider = ({ children }) => {
  const [store] = useState(new UserStore())
  return (
    <UserContext.Provider value={store}>
      {children}
    </UserContext.Provider>
  )
}

export const LessonProvider = ({ children }) => {
  const [store] = useState(new LessonStore())
  return (
    <LessonContext.Provider value={store}>
      {children}
    </LessonContext.Provider>
  )
}

export const GroupProvider = ({ children }) => {
  const [store] = useState(new GroupStore())
  return (
    <GroupContext.Provider value={store}>
      {children}
    </GroupContext.Provider>
  )
}

const providerError = (storeName) => {
  throw new Error(`You have forgotten to use ${storeName} provider`)
}

export const useUserStore = () => {
  const store = useContext(UserContext);
  if (!store) throw providerError("User Store")
  return store;
}

export const useLessonStore = () => {
  const store = useContext(LessonContext);
  if (!store) throw providerError("Lesson Store")
  return store
}

export const useGroupStore = () => {
  const store = useContext(GroupContext);
  if (!store) throw providerError("Group Store")
  return store;
}

export const CommentProvider = ({ children }) => (
  <CommentContext.Provider value={new CommentStore()}>
    {children}
  </CommentContext.Provider>
)

export const useCommentStore = () => {
  const store = useContext(CommentContext)
  if (!store) throw providerError("Comment Store")
  return store
}