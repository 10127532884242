import { client } from './client'

/**
 * Fetch group by id and fill empty fields with default data
 * @param { Number } id Group id
 * @returns {Object} Group Data
 */
export const fetchGroup = id =>
  client.service('groups').get(id, {
    query: {
      include: {
        service: 'members',
        as: 'groupMembers',
        attributes: ['role', 'userId'],
      }
    }
  })

/**
 * Fetches a groups members by group id.
 * Removes users with negative ids which appear in database.
 * Cleans table-join data to be more readable.
 * @param {[Number|Array]}  - groupId
 * @param {Boolean} - includeRemoved include removed users
 * @returns {Array} Member Data
 */
export const fetchGroupMembers = (groupId, includeRemoved) =>
  client.service('members').find({
    query: Object.assign({
      groupId: {
        $in: Array.isArray(groupId) ? groupId : [groupId]
      },
      userId: {
        $gt: 0
      },
      include: {
        service: 'user',
        as: 'user',
        attributes: ['firstName', 'username']
      }
    }, !includeRemoved && { role: { $ne: 'removed' } })
  })

export const getMemberById = id =>
  client.service('members').get(id, {
    query: {
      include: {
        service: 'user',
        as: 'user'
      }
    }
  })

/**
 * Fetches groups that a user is a member of.
 * Cleans table-join data and fills empty fields.
 * @param {Number} userId
 * @returns {Array} Group data
 */
export const fetchMembershipsWithGroups = userId =>
  client.service('members').find({
    query: {
      userId: userId || '$userId',
      $select: ['role', 'payed', 'id'],
      role: {
        $ne: 'removed'
      },
      $sort: {
        createdAt: -1
      },
      include: {
        service: 'groups',
        as: 'group',
        where: {
          status: 'active'
        }
      }
    }
  })

/**
 * Fetches groups with visibility field set to `public`.
 * Sorts by newest and fills empty fields.
 * @returns {Array} Public group data
 */
export const fetchGroupsPublic = ({ limit, offset = 0, searchQuery }) =>
  client.service('groups').find({
    query: {
      $limit: limit,
      $skip: offset,
      type: { 
        $ne: "gdc",
      },
      ...(searchQuery && {
        name: {
          $like: `%${searchQuery}%`
        }
      }),
      visibility: 'public',
      status: 'active',
      $sort: {
        updatedAt: -1
      },
      include: {
        service: 'members',
        as: 'groupMembers',
        attributes: ['role', 'userId'],
      }
    }
  })

/**
 * Creates group - which will assign user club admin server-side.
 * @param {Object} data Create group form data
 * @returns {Promise}
 */
export const createGroup = data =>
  client.service('groups').create(data)

/**
 * Edits a group
 * @param {Number} id Group id
 * @param {Object} data Edit group form data
 * @returns {Promise}
 */
export const editGroup = (id, data) =>
  client.service('groups').patch(id, data)


export const createMember = (userId, groupId, role = 'member') =>
  client.service('members').create({
    userId,
    groupId,
    role
  })


/**
 * Updates club member roles
 * @param {Array} members Members to update role
 * @param {String} role Role to change to
 * @returns {Promise}
 */
export const updateMembersRoles = (members, role) =>
  client.service('members').patch(
    null,
    { role },
    {
      query: {
        groupId: members[0].groupId,
        userId: {
          $in: members.map(member => member.userId)
        }
      }
    }
  )

/**
 * Attempts to register a member to a payment
 * @param {Number} id member id
 * @param {String} code payment code
 * @returns {Promise}
 */
export const registerMemberPayment = (id, code) =>
  client.service('members').patch(
    id,
    {
      payed: 1,
      code
    }
  )
