import React, { useEffect, useState, lazy, Suspense } from 'react'
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import PulseLoader from 'react-spinners/PulseLoader';
import {
  useGroupStore,
  useLessonStore,
  useUserStore,
  useCommentStore
} from '../state';
import AppBar from '../components/common/AppBar';
import Loading from '../components/common/Loading';
import ClubDashboard from '../components/club/ClubDashboard';
import { userIsAffiliatedWithClub, userIsClubAdmin } from '../utils/access-utils';
// TODO: remove if substantially past the date the comment was left
// 2022-03-10 - commented out until a decision is made on the future use of this component
// const ClubGames = lazy(() => import('../components/club/ClubGames'));
const ClubMembers = lazy(() => import('../components/club/ClubMembers'));
const ClubContacts = lazy(() => import('../components/club/ClubContact'));
const ClubAdmin = lazy(() => import('../components/club/ClubAdmin'));

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 48px)",
    backgroundColor: "white"
  },
  container: {
    marginTop: 48,
    paddingTop: 26,
    maxWidth: 860,
    width: "100%",
    height: "100%",
  },
}))

const Club = () => {
  const groupId = useParams().id
  const groupStore = useGroupStore()
  const classes = useStyles()
  const lessonStore = useLessonStore()
  const userStore = useUserStore()
  const commentStore = useCommentStore()
  const [activeTab, setActiveTab] = useState('Dashboard')
  
  const [isClubAdmin, setIsClubAdmin] = React.useState(false)
  const [isClubMember, setIsClubMember] = React.useState(false)

  React.useEffect(()=>{
    if (groupStore.currentClub) {
      setIsClubAdmin(userIsClubAdmin(userStore.currentUser, groupStore.currentClub))
      setIsClubMember(userIsAffiliatedWithClub(userStore.currentUser, groupStore.currentClub.id))
    }
  },[userStore.currentUser, groupStore.currentClub])

  useEffect(() => {
    const getData = async () => {
      await groupStore.getClubById(groupId)
      const { lessonIds } = groupStore.currentClub

      if (!lessonStore.lessons.length) {
        await lessonStore.getLessons()
      }
      lessonStore.loadLessons(lessonIds)

      commentStore.initialFetch(groupId)

      isClubMember && await groupStore.getClubMembers(isClubAdmin)
      isClubMember && await groupStore.getClubGames();

      window.parent.postMessage('gdc-ready', '*')
    }
    getData()
  }, [isClubMember, isClubAdmin])

  const { loadingClub } = groupStore

  const tabs = ['Dashboard']
  if (isClubAdmin && !loadingClub) tabs.push('Members')
  // TODO: remove if substantially past the date the comment was left
  // 2022-03-10 - commented out until a decision is made on the future use of this component
  // if (isClubMember && !loadingClub) tabs.push('Games')
  tabs.push('Contacts')
  if (isClubAdmin && !loadingClub) tabs.push('Settings')

  return (
    <>
      <AppBar
        isHome={false}
        onChange={val => setActiveTab(val)}
        tabs={tabs}
        value={activeTab}
      />

      <div className={classes.root}>
        <Container className={classes.container}>

          {
            (!loadingClub && groupStore.currentClub) ? (
              <>
                {activeTab === 'Dashboard' &&
                  <ClubDashboard />
                }

                {activeTab === 'Members' &&
                  <Suspense fallback={<Loading />}>
                    <ClubMembers />
                  </Suspense>
                }

                {
                  // TODO: remove if substantially past the date the comment was left
                  // 2022-03-10 - commented out until a decision is made on the future use of this component
                }
                {/* {activeTab === 'Games' &&
                  <Suspense fallback={<Loading />}>
                    <ClubGames />
                  </Suspense>
                } */}

                {activeTab === 'Contacts' &&
                  <Suspense fallback={<Loading />}>
                    <ClubContacts />
                  </Suspense>
                }

                {activeTab === 'Settings' &&
                <Suspense fallback={<Loading />}>
                  <ClubAdmin />
                </Suspense>
                }
              </>
            ) : (
              <div style={{ textAlign: 'center', marginTop: 84 }} >
                <PulseLoader width={180} color="rgb(67, 196, 255)" />
              </div>
            )
          }
        </Container>
      </div>
    </>
  )
}

export default observer(Club)