import { makeAutoObservable } from 'mobx'
import { fetchLessons } from '../api/lessons'

class LessonStore {
  
  constructor() {
    makeAutoObservable(this)
  }

  // <--- Observables --->
  lessons = []
  selectedLessons = []

  loadingLessons = false
  loadingLessonsError = undefined

  // <--- Actions --->
  loadLessons(lessonIds) {
    this.selectedLessons = this.lessons.filter(lesson => lessonIds.includes(String(lesson.id)))
  }

  addLesson(id) {
    this.selectedLessons.push(this.lessons.find(x => x.id === id))
  }

  removeLesson(id) {
    this.selectedLessons = this.selectedLessons.filter(lesson => lesson.id !== id)
  }

  // <--- Flow --->
  
  async getLessons () {
    this.loadingLessonsError = undefined
    this.loadingLessons = true
    try {
      const { data } = await fetchLessons()
      this.lessons = data
    } catch (err) {
      this.loadingLessonsError = err.message
      console.error(err)
    } finally {
      this.loadingLessons = false
    }
  }
}

export default LessonStore