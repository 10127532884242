import { groupCommentService } from './client'

export const fetchCommentsByGroup = async ({ groupId, limit, offset = 0 }) => {
  const { data, flags } = await groupCommentService.find({
    query: {
      $limit: limit,
      $skip: offset,
      $sort: {
        createdAt: -1
      },
      group_id: groupId,
      parent_comment_id: null,
      include: {
        service: 'user',
        as: 'author',
        required: true,
        attributes: ['id', 'username']
      },
      flags: {
        objectJoin: true,
        censor: true
      },
    }
  })

  return { data, flags }
}

export const createComment = (group_id, body, parent_comment_id) => {
  return groupCommentService.create({
    user_id: '$userId',
    group_id,
    body,
    parent_comment_id
  })
    .then(res => res.id)
}

export const updateComment = (id, body) => {
  groupCommentService.patch(
    id,
    {
      body
    }
  )
}

export const deleteComment = (id) => {
  groupCommentService.remove(id)
}