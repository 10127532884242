import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  progressContainer: {
    flex: 1,
    borderRadius: 20,
    border: "2px solid #bdbdbd",
    height: 20,
    overflow: 'hidden',
  },
  progressBar: {
    height: "100%",
    backgroundColor: '#00c853',
    border: "2px solid white",
    boxSizing: 'border-box',
    borderRadius: 20,
    alignSelf: 'center'
  }
}))

const Progress = ({ value }) => {
  const classes = useStyles()

  return (
    <div className={classes.progressContainer}>
      <div className={classes.progressBar} style={{ width: value + '%' }}/>
    </div>
  )
}

export default Progress
