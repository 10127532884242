import PulseLoader from 'react-spinners/PulseLoader'

const Loading = () => (
  <div style={{ marginTop: 24, textAlign: 'center'}}>
    <PulseLoader
      loading
      color='rgb(67, 196, 255)'
    />
  </div>
)

export default Loading