import React from 'react'
import { observer } from 'mobx-react-lite'
import { Waypoint } from 'react-waypoint'
import PulseLoader from 'react-spinners/PulseLoader'

import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/styles'

import { useGroupStore, useUserStore} from '../../state'

import ClubEntry from './ClubEntry'
import AnimatedBanner from './AnimatedBanner'
import GroupSearch from '../common/GroupSearch'
import { userHasClubsAdminAccess } from '../../utils/access-utils';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    margin: 'auto',
    marginTop: 24,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  clubCard: {
    width: "100%"
  },
  findClubs: {
    maxWidth: 860,
    margin: 'auto'
  }
}))

const FindClubs = props => {
  const groupStore = useGroupStore()
  const userStore = useUserStore()
  const classes = useStyles()

  const [canUserCreateClubs, setCanUserCreateClubs] = React.useState(false);
  React.useEffect(()=>{
    setCanUserCreateClubs(userHasClubsAdminAccess(userStore.currentUser))
  },[userStore.currentUser])

  return (
    <div>
      <Grid className={classes.gridContainer} container >
        <Grid item>
          <Typography variant={'h1'}>
            Clubs
          </Typography>
        </Grid>

        <Grid item>
          <GroupSearch />
        </Grid>
      </Grid>

      <AnimatedBanner
        showCreate={canUserCreateClubs}
        handleCreate={() => props.handleChangeTab('Create Club')}
      />

      <Grid container spacing={2} className={classes.gridContainer}>
        {groupStore.publicClubs
          .map((club, i) => (
            <Grid item key={i} className={classes.clubCard}>
              <ClubEntry
                key={club.id}
                i={i}
                id={club.id}
                icon={club.name.substring(0, 1).toUpperCase() || "U"}
                name={club.name.length ? club.name : "Untitled Club"}
                organizer={club.organiserName}
                createdAt={club.createdAt}
                destination={club.location}
                groupMembers={club.groupMembers}
              />
            </Grid>
          ))
        }
      </Grid>
      {!groupStore.loadingClubs && !groupStore.outOfClubs && <Waypoint onEnter={() => groupStore.extend()} />}
      {!groupStore.outOfClubs && 
        <PulseLoader
          loading={true}
          color="rgb(67, 196, 255)"
          width={180}
        />
      }
    </div>
  )
}

export default observer(FindClubs)
