import PulseLoader from 'react-spinners/PulseLoader'
import { Waypoint } from 'react-waypoint'

import { makeStyles } from '@material-ui/styles'
import { Typography, Button, Grid } from '@material-ui/core';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    minHeight: theme.spacing(10),
  },
  button: {
    margin: theme.spacing(1)
  },
  icon: {
    marginLeft: theme.spacing(1)
  }
}))

const defaultMarker = (
  <Typography>
    {'End of Results'}
  </Typography>
)

const LoadMore = ({
  auto,
  loading,
  getNext,
  hasMore,
  displayEOR = true,
  endMarker = defaultMarker
}) => {
  const classes = useStyles()

  const eor = typeof endMarker === 'string'
    ? (
      <Typography style={{ fontSize: 14 }}>
        {endMarker}
      </Typography>
    ) : endMarker

  return (
    <Grid className={classes.root} container item xs={12} justify='center'>
      {hasMore
        ? loading ?
          <PulseLoader
            loading={true}
            color="rgb(67, 196, 255)"
            width={180}
          />
          : auto ?
            <Waypoint onEnter={() => getNext()} />
            : (
              <Button
                className={classes.button}
                onClick={getNext}>
                <Typography>
                  {'Load More'}
                </Typography>
                <DownArrowIcon className={classes.icon} />
              </Button>
            )
        : displayEOR
          ? eor
          : <></>
      }
    </Grid>
  )
}

export default LoadMore
