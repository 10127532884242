/**
 * Picks across a gradient of colours.
 * @param {number} i - Index of a component map.
 */

const colours = [
  '#ffc400',
  '#ff9800',
  '#ff5722',
  '#d81b60',
  '#aa00ff',
  '#3f51b5',
  '#2196F3',
  '#26c6da',
  '#00C853'
]

const coloursNew = [
  '#f524a8',
  '#1CD9F7',
  '#F26730',
  '#CB37FD',
  '#00C953',
  '#1989F7',
  '#F4BF0F'
]

export const pickColour = (i, saturatedPallette) => (saturatedPallette ? coloursNew : colours)[i % (saturatedPallette ? coloursNew : colours).length]