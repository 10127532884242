import React from 'react'
import { observer } from 'mobx-react-lite'

import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'


import { makeStyles } from '@material-ui/styles'

import {
  useLessonStore,
  useGroupStore
} from '../../../state'

const useStyles = makeStyles(theme => ({
  textButton: {
    fontFamily: "'Fredoka One', cursive;",
    textTransform: "none",
    fontSize: 16,
  }
}))

const LessonDisplay = () => {
  const classes = useStyles()
  const {
    selectedLessons
  } = useLessonStore()

  const { initialLoad } = useGroupStore()

  const handleRequestOpenTutorial = (directory) => {
    const splitDirectoryArray = directory.split("/")
    const folderName = splitDirectoryArray[(splitDirectoryArray.length - 1)]
    window.parent.postMessage({message: "gdc-open-tutorial", tutorial: folderName}, "*");
  };

  return (
    !!selectedLessons.length
      ?
        <div className={classes.container} style={{marginBottom: 48}}>
          <Fade in={true} timeout={initialLoad ? 200 : 0}>
            <>
              <Typography variant={'h2'}>
                {`Current Lesson${selectedLessons.length > 1 ? 's' : ''}`}
              </Typography>
              <Typography variant="subtitle1">
                Tutorials to follow for this term
              </Typography>
              {selectedLessons.map(lesson => (
                <Button
                  key={lesson.id}
                  onClick={() => handleRequestOpenTutorial(lesson.directory)}
                >
                  <img src={lesson.image} alt="" />
                </Button>
              ))}
            </>
          </Fade>
        </div>
      : null
  )
}

export default observer(LessonDisplay)