import { client } from './client'

/**
 * Fetches multiple users published projects with urls attached.
 * If showDrafts is specified remove url from games that have been
 * explicitly un-published by a user.
 * @param {Array} ids User ids
 * @param {Boolean} showDrafts Get draft projects only
 * @returns {Array} Latest published versions of users projects with urls
 */
export const fetchProjectsByUser = async (ids, showDrafts, showPublished, limit, offset = 0) => {
  const { data } = await client.service('projects').find({
    query: Object.assign(
      {
        owner: { $or: [ids ? ids : ['$userId']] },
        $sort: {
          updatedAt: -1
        },
        $skip: offset,
        $limit: limit,
        include: [
          {
            service: 'games-likes',
            as: 'likes',

          },
          {
            service: 'user',
            as: 'creator',

          },
          {
            service: 'game-comments',
            as: 'comments',
            attributes: ['id'],

          }
        ]
      },
      showDrafts && { meta: { $nin: ['{"published":true}'] } },
      showPublished && { meta: '{"published":true}' },
    )
  })
  return showDrafts ? data.map(({ url, ...otherKeys }) => otherKeys) : data
}
