import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useGroupStore, useLessonStore, useUserStore } from "../state";
import useQuery from "../hooks/use-query";

const urls = {
  development: "http://dev.gamefroot.com:3000",
  production: "https://make.gamefroot.com",
  staging: "https://staging.gamefroot.com",
};

export const url = urls[process.env.REACT_APP_GAMEFROOT_ENV];

/**A utility component to control fetching initial data required by each route,
 * this component does not render anything*/
const AuthUtility = observer(() => {
  // hooks
  const groupStore = useGroupStore()
  const lessonStore = useLessonStore()
  const userStore = useUserStore();
  const query = useQuery();

  useEffect(() => {
    const doLogin = async () => {
      try {
        const token = query.get("token");
        console.log(token);
        await userStore.loginJWT(token);
        console.log(userStore.currentUser);
      } catch (err) {
        console.log(err);
        // redirect back to gamefroot if not logged in
        window.location = url
        // TODO: Display a login failure page in place of redirect.
      }
    };

    const fetchData = async () => {
      if (!groupStore.publicClubs.length) {
        await groupStore.extend();
        await groupStore.getClubsUser(userStore.currentUser.id);
      }
      
      if (!lessonStore.lessons.length) {
        lessonStore.getLessons()
      }
      
    }
    
    doLogin();
    fetchData();
    window.parent.postMessage("gdc-ready", "*");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return null;
});

export default AuthUtility;
